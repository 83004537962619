<template>
    <div ref='screen-container' class='screen-container'>
      <div ref='screen' class='screen'>
          <span class='title title-initial glowText'>Super Video Ball</span>
          <span ref='scoreboard' class='score-board'>:</span>
          <div ref='game-container' class='game-container'>
            <div ref='phaser-container' id='phaser-container'></div>
            <div ref='game-message' class='game-message glowText'></div>
          </div>
          <span ref='players' class='score-board'>
                <span>{{player1.chosenName}}</span>
                <span> VS </span>
                <span>{{player2.chosenName}}</span> 
          </span>
      </div>
    </div>
</template>

<script>
import Paddle from './../svb/Paddle'
import Ball from './../svb/Ball'

import onepx_png from './../svb/assets/1px.png'
import ball_png from './../svb/assets/ball.png'
import paddle_png from './../svb/assets/paddle.png'

export default {

  props: {
    network: Object,
    player1: {
      type: Object,
      default: () => { return {} },
    },
    player2: {
      type: Object,
      default: () => { return {} },
    },
    cpu1: {
      type: Boolean,
      default () { return false },
    },
    cpu2: {
      type: Boolean,
      default () { return false },
    },
    onGameReady: {
      type: Function,
      default () { () => {} },
    },
  },

  data() {
    return {
      element: null,
      scoreBoard: null,
      gameContainer: null,

      game: null,
      sound: null,

      paddle1: null,
      paddle2: null,
      ball: null,

      score: null,

      cpu1data: {},
      cpu2data: {},
    }
  },

  mounted() {
    this.score = [0, 0]

    this.createGame()
  },

  methods: {

    terminate() {
      this.game.destroy(true, false)
    },

    checkDimensions() {
      // debugger
      const screenContainer = this.$refs['screen-container']
      const screen = this.$refs['screen']
      const width = screen.offsetWidth
      const height = screen.offsetHeight
      const containerWidth = screenContainer.offsetWidth
      const containerHeight = screenContainer.offsetHeight

      let transform = '';

      // center screen in screen-container
      transform += `translate(${containerWidth/2-width/2}px,${containerHeight/2 - height/2}px)`
      
      // determine the ratio of the content
      const ratio = width / height
      const containerRatio = containerWidth / containerHeight
      
      // width restricted
      if (ratio > containerRatio) {

        const factor = containerWidth / width
        transform += `scale(${factor})`
      }
      // height restricted
      else {
        const factor = containerHeight / height
        transform += `scale(${factor})`
      }

      // set
      screen.style.transform = transform;
    },

    createGame() {

      // window.addEventListener('resize', this.checkDimensions)
      // window.addEventListener('orientationchange', this.checkDimensions)

      this.game = new window.Phaser.Game({
        forceSetTimeOut: false,
        width: 700, height: 400, rederer: window.Phaser.WEBGL, parent: 'phaser-container', state: {

        preload: () => {
          // Prevent the game from sleeping if focus is lost
          this.game.stage.disableVisibilityChange = true

          // Load stuff
          this.game.load.image('wall', onepx_png)
          this.game.load.image('paddle', paddle_png)
          this.game.load.image('ball', ball_png)

          this.game.load.script('BlurX', '/lib/filters/blurx.js')
          this.game.load.script('BlurY', '/lib/filters/blury.js')
          this.game.load.script('BallBlur', '/lib/filters/ballblur.js')

          this.game.load.audio('sfx', '/sound.mp3')
        },

        create: (game) => {
          // console.log('Screen.createGame.Phaser.create', game)

          //	Set up audo sprite  
          this.sound = game.add.audio('sfx')
          this.sound.allowMultiple = true
          this.sound.addMarker('ball', 0, 1.0)
          this.sound.addMarker('paddle left', 2, 1.0)
          this.sound.addMarker('paddle right', 4, 1.0)
          this.sound.addMarker('default', 6, 1.0)

          // Setup scaling
          this.game.scale.scaleMode = window.Phaser.ScaleManager.NO_SCALE
          this.game.scale.refresh()

          // Setup physics
          game.physics.startSystem(window.Phaser.Physics.ARCADE)

          // Setup paddles
          this.paddle1 = new Paddle(this.game, 'left', this.sound)
          this.paddle2 = new Paddle(this.game, 'right', this.sound)

          // Setup ball
          this.ball = new Ball(this.game, this.ballScored, this.sound)

          // Prevent bouncing left and right
          game.physics.arcade.checkCollision.left = false
          game.physics.arcade.checkCollision.right = false

          // Display the game
          this.$refs['game-container'].classList.add('ready')

          // Setup callbacks
          if(this.network) {
            this.setupCallbacks()
          }

          // Scale screen
          this.checkDimensions()

          // Start the game
          this.updateScore()

          // Got to wait a bit
          setTimeout(this.onGameReady, 500)
        },

        update: (game) => {
          // console.log('Screen.createGame.Phaser.update', game)
          if (this.cpu1) {
            // check position of ball
            if (this.ball.sprite.position.y < this.paddle1.sprite.position.y - 25) {
              this.manipulatePaddle(0, 'up')
            }
            else if (this.ball.sprite.position.y > this.paddle1.sprite.position.y + 25) {
              this.manipulatePaddle(0, 'down')
            }
            else {
              this.manipulatePaddle(0, 'neutral')
            } 
          }

          // Update ball
          this.ball.update()

          // Update paddles
          this.paddle1.update()
          this.paddle2.update()

          // Test for collisions with paddles
          game.physics.arcade.collide(this.ball.sprite, this.paddle1.sprite, this.ballHitPaddle, null, this)
          game.physics.arcade.collide(this.ball.sprite, this.paddle2.sprite, this.ballHitPaddle, null, this)
        },

        render: () => {

        }
      }});

      
    },

    setupCallbacks() {
      this.network.callbacks['paddleMove'] = (msg) => {

        // console.log('paddleMove', msg, this.player1Id)

        if(msg.cid === this.player1.identity) {
          this.manipulatePaddle(0,msg.msg)
        }
        if(msg.cid === this.player2.identity) {
          this.manipulatePaddle(1,msg.msg)
        }
      }
    },

    ballScored(side) {
      switch (side) {
        case( 'left') :
          this.sound.play('default');
          this.score[1]++
          break
        case( 'right') :
          this.sound.play('default');
          this.score[0]++
          break
        default:
          break
      }
      this.updateScore()
    },

    updateScore() {
      this.sound.play('default');
      this.$refs['scoreboard'].classList.add('glowText')
      this.$refs['scoreboard'].innerHTML = this.score[0] + ' : ' + this.score[1]

      setTimeout(function() {
        this.$refs['scoreboard'].classList.remove('glowText')
      }.bind(this), 2000)

      if(this.score[0] === 5) {
        this.playerWon('left')
      }
      else if(this.score[1] === 5) {
        this.playerWon('right')
      }
      else {
        this.ball.restart()
      }
    },

    playerWon(side) {
      var playerNumber
      switch(side) {
        case('left') :
          playerNumber = 1
          break
        case('right') :
          playerNumber = 2
          break
        default:
          break
      }

      this.$refs['game-message'].innerHTML = 'Player ' + playerNumber + ' won!'
      this.$refs['game-message'].style.visibility = 'visible'

      setTimeout(function() {
        this.$refs['game-message'].style.visibility = 'hidden'
        this.score = [0, 0]
        this.updateScore()
        this.ball.restart()
      }.bind(this), 6000)
    },

    ballHitPaddle(ball, paddle) {
      // Send the event to the ball and paddle instances
      ball.owner.collidePaddle(paddle.owner)
      paddle.owner.collideBall(ball.owner)
    },

    manipulatePaddle(playerIndex, direction) {
      switch (playerIndex) {
        case( 0) :
          this.paddle1.direction = direction
          break
        case( 1) :
          this.paddle2.direction = direction
          break
        default:
          break
      }
    },
  }

}
</script>

<style lang="scss">
.screen-container {
  flex: 1;
  position: relative;
}
.screen {
  @import './../../../colors.scss';

  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;

  display: flex;
  flex-direction: column;
  justify-content: center;

  transform-origin: center;

  .game-container {
    text-align: center;
    overflow: visible;
    transition: opacity 1.5s ease-in-out;
    opacity: 0;

    &.ready {
      opacity: 1;
    }
  }

  #phaser-container {
    canvas {
      border: 2px solid $main;
    }
  }

  .title {
    transition: all 1.5s ease-out;
    display: none;
  }

  .title-initial {
    font-size: 2.5em;
  }

  .title-in-game {
    font-size: 1.5em;
  }

  .score-board {
    display: block;
    margin: 0px;
    padding: 15px;
    font-size: 1.5em;
    // display: none;
  }

  .game-message {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    font-size: 1.5em;
  }

}

</style>