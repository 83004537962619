<template>
    <div class="container">
        <template v-if="true || !this.connection">
            <h2 class="title glowText">CONNECTING...</h2>
            <button v-on:click="reloadPage">Retry</button>
            <button v-on:click="stop">Stop</button>
        </template>
        <template v-else>

        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        components: {
        },
        computed: {
            roomName () {
                return this.$route.params.roomName
            },
            ...mapGetters('NetworkInterface', [
                'connection',
            ]),
        },

        watch: {
            async connection(newVal) {
                console.log('update connection', newVal)
                if (newVal) {
                    console.log('request room info')
                    this.$store.dispatch('NetworkInterface/requestRoomInfo')
                }
            },
        },

        mounted() {
            if (!this.connection) {
                this.$store.dispatch('NetworkInterface/joinRoom', { roomName: this.roomName })
            }
            else {
                this.proceed()
            }
        },

        methods: {
            proceed() {
                if (this.connection) {
                    // this.$_routeAnimation('drilldown', this.ANIMATIONS.slideRight)
                    this.$router.push({ name: this.Lobby.routes.Joined.name, params: { } })
                }
            },
            reloadPage() {
                window.location.reload()
            },
            stop () {
                sessionStorage.session = null
                this.$router.push({ name: this.Lobby.routes.Default.name, params: { } })
            }
        }
    }
</script>
