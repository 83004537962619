<template>
    <div ref='game-screen' class='game-screen' :class='{ ready: isReady }'>
        <ScreenMenu>
            <button class="stop-button" v-on:click="stopGame">STOP</button>
        </ScreenMenu>
        <div class="game">
            <div class="pad"></div>
            <Screen ref='screen' :network="network" :player1="player1" :player2="player2" :onGameReady="onGameReady"></Screen>
            <div class="pad"></div>
        </div>
    </div>   
</template>

<script>
import Screen from './../components/Screen'
import ScreenMenu from './../components/ScreenMenu'

import { mapGetters } from 'vuex'

export default {
    components: {
        Screen,
        ScreenMenu,
    },
    data() {
        return {
            isReady: false,
        }
    },
    props: {
        player1: {
            type: Object,
            default () { return { chosenName: 'Player 1'} },
        },
        player2: {
            type: Object,
            default () { return { chosenName: 'Player 2'} },
        },
    },
    computed: {
        ...mapGetters('NetworkInterface', [
            'network',
            'playing',
        ]),
    },
    watch: {
        playing(newVal) {
            console.log('Screen.vue playing changes', newVal)
            if (newVal == false) {
                this.stopGame()
            }
        },
    },
    methods: {
        onGameReady () {
            this.isReady = true
        },
        stopGame () {
            this.$router.push({ name: this.Lobby.routes.Created.name, params: { } })
        },

        fixDisplay() {
            // if the screen is taller than wide
            // adjust the main element to have width = height etc
            // make it absolute
            // rotate it
            
            if (window.innerHeight > window.innerWidth) {
                this.$refs['game-screen'].setAttribute('style',`position: absolute; width: ${window.innerHeight}px; height: ${window.innerWidth}px; top: 0; left: 0; transform: rotate(90deg) translate(0, -${window.innerWidth}px); transform-origin: top left;`)    
            }
            else {
                this.$refs['game-screen'].setAttribute('style','')
            }

            setTimeout(() => {
                this.$refs['screen'].checkDimensions()
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        console.debug(to)
        console.debug(from)
        this.isReady = false
        setTimeout(() => {
            this.$refs.screen.terminate()
            next()
        }, 1)
    },

    mounted () {
        console.log('MOUNTED ', this)
        window.addEventListener('resize', this.fixDisplay)
        screen.orientation.onchange = this.fixDisplay
        // window.addEventListener('orientationchange', this.fixDisplay)
        this.fixDisplay()
        this.$store.dispatch('NetworkInterface/startGame')
    },
    destroyed () {
        this.$store.dispatch('NetworkInterface/stopGame')
    },
}
</script>

<style lang="scss" scoped>
.game-screen {
  @import './../../../colors.scss';

  color: $main;
  height: 100vh;

  opacity: 0;
  &.ready {
    opacity: 1;
  }
  .pad {
    width: 2em;
  }   
  .game {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
  }
}
.player-names {

    text-align: center;
    span {
        padding: 1em;
    }
}
</style>