import Vue from 'vue'
import VueRouter from 'vue-router'

import Lobby from './modules/lobby'
import Game from './modules/game'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...Lobby.routes,
    ...Game.routes,
  ],
})

export default router
