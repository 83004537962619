<template>
    <div ref="element" class="modal-menu">
        <div class="options-panel">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {

    props: {
    },

    data() {
        return {
        }
    },

    mounted() {
    },

    methods: {
    }

}
</script>

<style lang="scss">
    @import './../../../colors.scss';

    .modal-menu {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;

        background-color: rgba(0,0,0,0.8);


        .options-panel {

        }
    }
</style>