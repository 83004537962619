class Ball {

  direction = 1 /* 1 or -1 */
  startSpeed = 300
  startTimeout = 1500
  refraction = 10 /* influences the angle of bounces */
  targetVelocity = undefined /* dynamic, slows down to this velocity after a hit */
  afterHitFactor =  2 /* the (temporary) increase in speed after a hit */
  centerHitFactor = 1.2 /* the increase in speed after a center hit */

  ballScoredCallback = null

  filters = null
  sprite = null
  sound = null

  constructor(game, ballScoredCallback, sound) {
    this.game = game
    this.ballScoredCallback = ballScoredCallback
    this.sound = sound

    this.sprite = game.add.sprite(game.world.centerX, game.world.centerY, 'ball')
    this.sprite.anchor.set(0.5)

    // Physics
    game.physics.enable(this.sprite, window.Phaser.Physics.ARCADE)
    this.sprite.checkWorldBounds = true
    this.sprite.body.collideWorldBounds = true
    this.sprite.body.bounce.set(1)
    this.sprite.events.onOutOfBounds.add(this.outOfBounds, this)

    // Add parent property in the sprite, to recognize it in window.Phaser callbacks
    if(Object.prototype.hasOwnProperty.call(this.sprite,'owner')) {
    // if(this.sprite.hasOwnProperty('owner')) {
      console.error('Property already defined')
    }
    this.sprite.owner = this

    // Create and store filters
    this.filters = {}
    this.filters.blur = game.add.filter('BallBlur');
    this.sprite.filters = [this.filters.blur]
  }

  reset() {
    this.targetVelocity = undefined

    this.sprite.x = this.game.world.centerX
    this.sprite.y = this.game.world.centerY

    this.sprite.body.velocity.x = 0
    this.sprite.body.velocity.y = 0
  }

  start() {
    setTimeout(function () {
      this.targetVelocity = this.startSpeed * this.direction
      this.sprite.body.velocity.x = this.targetVelocity
    }.bind(this), this.startTimeout)
  }

  restart() {
    this.reset()
    this.start() /* delayed by this.startTimeout */
  }

  outOfBounds() {

    // Reverse the direction for the next serve
    this.direction = this.sprite.body.velocity.x > 0 ? 1 : -1

    // Translate into which side scored a point
    let sideScored = this.direction > 0 ? 'right' : 'left'

    this.ballScoredCallback(sideScored)
  }

  render() {

  }

  update() {

    // Slow down towards the target velocity
    if (Math.abs(this.sprite.body.velocity.x) > Math.abs(this.targetVelocity)) {
      this.sprite.body.velocity.x *= 0.99
    }

    // Put motion blur on the ball, a little more pronounced on the Y axis
    this.filters.blur.blurx = this.sprite.body.velocity.x / 30
    this.filters.blur.blury = -this.sprite.body.velocity.y / 20
    this.filters.blur.padding = 50
  }

  collidePaddle(paddle) {
    this.sprite.body.velocity.x = -this.targetVelocity //|| this.sprite.body.velocity.x

    // Bounce the ball depending on where it hits
    var diff = this.sprite.y - paddle.sprite.y
    this.sprite.body.velocity.y = (this.refraction * diff)

    // When it hits close to the center
    if(diff < this.refraction && diff > -this.refraction) {
      this.sound.play('ball');
      this.sprite.body.velocity.x *= this.centerHitFactor
      paddle.perfectHit(this)
    }

    // Store the new target velocity and give the ball extra speed
    this.targetVelocity = this.sprite.body.velocity.x
    this.sprite.body.velocity.x *= this.afterHitFactor

    // Prevent the ball going to fast
    if(this.sprite.body.velocity.x > 2400) {
      this.sprite.body.velocity.x = 2400
    }
    if(this.sprite.body.velocity.x < -2400) {
      this.sprite.body.velocity.x = -2400
    }

  }

}

export default Ball
