const Bugout = require('bugout')
let b = null
const Network = require('../../network').default
const session = require('../../network/session').setup()

import {
  get
} from 'lodash-es'

export default {
  namespaced: true,
  state: {
    network: null,
    connection: null,
    session,
    room: {
      gameHost: null,
      screen: null,
      players: [],
      selectedPlayers: [],
      playing: false,
    },
  },
  mutations: {
    updatePlayers(state, payload) {
      state.room.players = payload.players
    }
  },
  actions: {
    setup({
      state,
      dispatch
    }, payload) {
      console.log('setup network', Network, state)
      const network = Network.setup(payload.bugout, state.session)
      
      // Get room info
      network.callbacks['request-room-info'] = function (message) {
        console.log('request room info', message.msg)
        if (state.session.isScreen()) {
          dispatch('sendRoomInfo')
        }
      }

      // Get room info
      network.callbacks['send-room-info'] = function (message) {
        console.log('send room info', message.msg)
        if (state.session.isController()) {
          console.log('received room info', message.msg)
          state.room = message.msg
        }

        if (state.session.isScreen()) {
          if (message.msg.playing) {
            state.room.playing = true
          }
        }
      }


      network.callbacks['request-set-player'] = function (message) {
        if (state.session.isScreen()) {
          dispatch('setPlayer', message)
        }
      }
      network.callbacks['request-start-game'] = function (message) {
        if (state.session.isScreen()) {
          dispatch('startGame')
        }
      }
      network.callbacks['request-stop-game'] = function (message) {
        if (state.session.isScreen()) {
          dispatch('stopGame')
        }
      }

      // Handle disconnects
      network.callbacks['disconnected-player'] = function (message) {
        console.log('disconnected player', message.msg)
      }

      // When players are connected
      network.callbacks['player-ready'] = (message) => {
        console.log('player is ready ', message, state)
        dispatch('addPlayer', message.msg)
      }

      // Handle incoming profile updates
      network.callbacks['profile-change'] = (message) => {
        console.log('profile changed', message)
        dispatch('updateProfile', message.msg)
      }

      state.network = network
    },
    requestRoomInfo({state,
      dispatch}, payload) {
        console.log('getRoomInfo', dispatch, state, payload)
        state.network.send('request-room-info', {})
    },
    sendRoomInfo({state,
      dispatch}, payload) {
        console.log('sendRoomInfo', dispatch, state, payload)
        state.network.send('send-room-info', state.room)
    },
    async createServer({
      state,
      dispatch
    }) {
      const b = new Bugout({
        seed: localStorage["bugout-server-seed"],
        announce: [`wss://supervideoball.com/ws`],
        iceServers: [{
          urls: "stun:openrelay.metered.ca:80"
        }]
      })
      state.connection = b
      // save this server's session key seed to re-use
      localStorage["bugout-server-seed"] = b.seed
      await dispatch('setup', {
        bugout: b
      })
      state.session.setModeScreen()
    },
    async joinRoom({
      state,
      dispatch
    }, payload) {
      console.log('joinRoom')
      b = new Bugout(payload.roomName, {
        announce: [`wss://supervideoball.com/ws`],
        iceServers: [{
          urls: "stun:openrelay.metered.ca:80"
        }]
      });
      await dispatch('setup', {
        bugout: b
      })

      // wait until we see the server
      b.on('server', (address) => {
        console.log('connected to server', address)
        state.session.setRoomName(payload.roomName)
        state.session.setModeController()
        state.connection = b.address()
        state.network.send('player-ready', {
          peerId: state.network.bugout.address(),
          identity: state.session.identity,
          chosenName: state.session.chosenName
        })
      });

      // save this client instance's session key seed to re-use
      localStorage['bugout-seed'] = JSON.stringify(b.seed)
    },
    
    addPlayer({
      state,
      dispatch
    }, payload) {
      console.log('addPlayer', state, payload)
      let player = state.room.players.find((p) => {
        return p.identity === payload.identity
      })
      if (!player) {
        state.room.players.push({
          identity: payload.identity,
          peerId: payload.peerId,
          connected: true
        })
      } else {
        player.peerId = payload.peerId
        player.chosenName = payload.chosenName
        player.connected = true
      }
      dispatch('sendRoomInfo')
    },

    removePlayer({
      state,
      dispatch
    }, payload) {
      let player = state.room.players.find((p) => {
        return p.peerId === payload.player.peerId
      })
      if (player) {
        player.connected = false
      }
      dispatch('sendRoomInfo')
    },
    
    updateProfile({
      state,
      commit
    }, payload) {
      console.log("process update", payload)
      let player = state.room.players.find((p) => {
        return p.identity === payload.identity
      })
      if (player) {
        player.chosenName = payload.chosenName
        player.peerId = payload.peerId
      }
      console.log('updated chosenName', state, this.state)
      commit('updatePlayers', {
        players: [...state.room.players]
      })
    },

    requestSetPlayer({
      state,
    }, payload) {
      state.network.send('request-set-player', payload)
    },
    requestStartGame({
      state,
    }) {
      state.network.send('request-start-game')
    },
    requestStopGame({
      state,
    }) {
      state.network.send('request-stop-game')
    },
    
    setPlayer({
      state,
      dispatch,
    }, payload) {
      console.log('setPlayer', payload, state.room)
      
      let found = false

      state.room.selectedPlayers.forEach(sp => {
        if (sp.id == payload.msg.id) {
          found = true
          sp.identity = payload.msg.player.identity
        }
        else if (sp.identity == payload.msg.player.identity) {
          sp.identity = null
        }
      })
      if (!found) {
        state.room.selectedPlayers.push({ id: payload.msg.id, identity: payload.msg.player.identity})
      }

      console.log('setPlayer done', state.room.selectedPlayers)

      dispatch('sendRoomInfo')
    },

    startGame({
      state,
      dispatch
    }) {
      console.log('startGame')
      state.room.playing = true
      dispatch('sendRoomInfo')
    },
    
    stopGame({
      state,
      dispatch
    }) {
      console.log('stopGame')
      state.room.playing = false
      dispatch('sendRoomInfo')
    },
    
    broadCastProfile({
      state
    }) {
      state.network.send('profile-change', {
        peerId: state.network.bugout.address(),
        identity: state.session.identity,
        chosenName: state.session.chosenName
      })
    },
    
    setName({
      state,
      dispatch
    }, payload) {
      state.session.setChosenName(payload.name)
      dispatch('broadCastProfile')
    }

  },
  getters: {
    network: (state) => {
      return state.network
    },
    room: (state) => {
      return state.room
    },
    connection: (state) => {
      return state.connection
    },
    session: (state) => {
      return state.session
    },
    playing: (state) => {
      return state.room.playing
    },
    address: (state) => {
      return get(state, 'network.bugout.identifier')
    },
    chosenName: (state) => {
      return get(state, 'session.chosenName')
    },
    players: (state) => {
      try {
        return state.room.players
      } catch (e) {
        return []
      }
    },
    selectedPlayers: (state) => {
      return state.room.selectedPlayers
    }
  },
}
