module.exports = {

  // engine: localStorage,
  engine: sessionStorage,

  setKey: function(key,value) {
    this.engine[key] = value
  },

  getKey: function(key) {
    return this.engine[key]
  }

}
