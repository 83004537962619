import Index from './containers/Index'
import Test from './containers/Test'
import Screen from './containers/Screen'
import Controller from './containers/Controller'

/* Route specification */
export const Info = {
  _id: 'MODULE_GAME',
  name: `Game`,
  path: 'game',
  icon: '',
  routes: {
    PlayerVersusPlayer: {
      name: 'PlayerVersusPlayer',
      path: 'player-vs-player',
      icon: '',
      props: true
    },
    PlayerVersusComputer: {
      name: 'PlayerVersusComputer.',
      path: 'player-vs-computer',
      icon: '',
      props: { default: true, cpu1: true }
    },
    Screen: {
      name: 'Screen',
      path: 'screen',
      icon: '',
      props: true
    },
    Controller: {
      name: 'Controller',
      path: 'controller',
      icon: '',
    }
  },
}

/* Route relations */
export default {
  Info,
  routes: [{
    path: `/game`,
    component: Index,
    children: [
      {
        ...Info.routes.PlayerVersusPlayer,
        component: Test,
      },
      {
        ...Info.routes.PlayerVersusComputer,
        component: Test,
      },
      {
        ...Info.routes.Screen,
        component: Screen,
      },
      {
        ...Info.routes.Controller,
        component: Controller,
      },
    ],
  }],
}
