import { Info as Lobby } from '../lobby'
import { Info as Game } from '../game'

export const SubModules = [Lobby, Game]

export const checkPath = (path) => {
  return window.location.pathname.includes(path)
}

export const currentModule = () => {
  return SubModules.find(module => checkPath(`/${module.path}`)) || Lobby
}

export default {
  data () {
    return {
      Lobby,
      Game,
      SubModules,
    }
  },
  computed: {
    currentModule,
  },
  methods: {
    checkPath,
  },
}
