<template>
    <div class="container">
        <h1 class="title glowText">Super Video Ball</h1>
        <button v-on:click="this.scanCode" data-cy="scan-code">Scan a code to connect</button>
        <button v-on:click="this.createRoom" data-cy="create-room">Play with friends on this screen</button>
        <button v-on:click="this.startGame" data-cy="test-game">Play 1 VS 1 on this device</button>
        <button v-on:click="this.startGameAgainstCpu" data-cy="vs-cpu">Play VS your CPU</button>
        <footer>
            <a class="mastodon" rel="me" href="https://mastodon.gamedev.place/@michiel"><img src="mastodon.svg"></img></a>
        </footer>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters('NetworkInterface', [
            ])
        },
        watch: {
        },
        mounted() {
            // this.$toasted.show('Hello', {
            //     duration: 2000
            // })
        },
        methods: {
            createRoom() {
                this.$router.push({ name: this.Lobby.routes.Create.name, params: {}});
            },
            startGame() {
                this.$router.push({ name: this.Game.routes.PlayerVersusPlayer.name, params: {}})
            },
            startGameAgainstCpu() {
                this.$router.push({ name: this.Game.routes.PlayerVersusComputer.name, params: {}})
            },
            scanCode() {
                this.$router.push({ name: this.Lobby.routes.Scan.name, params: {}})
            },
        },
    }
</script>

<style lang="scss">
.mastodon {
    border: none;
    img {
        max-width: 150px;
        filter: grayscale(1) saturate(2.2);
    }
    
}

</style>