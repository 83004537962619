export default {
    setup: (bugout, session) => {
        console.debug('network setup')
        const callbacks = {}

        const send = require('./parser')(bugout, session, callbacks)
        
        return {
            bugout,
            send,
            callbacks
        }
    }
}
