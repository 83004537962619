import Index from './containers/Index'
import Start from './containers/Start'
import Create from './containers/Create'
import Join from './containers/Join'

const Scan = () => import(/* webpackChunkName: "js/lobby" */ './containers/Scan')
const Joined = () => import(/* webpackChunkName: "js/lobby" */ './containers/Joined')
const Created = () => import(/* webpackChunkName: "js/lobby" */ './containers/Created')

/* Route specification */
export const Info = {
  _id: 'MODULE_LOBBY',
  name: `Lobby`,
  path: '',
  icon: '',
  routes: {
    Default: {
      name: 'Start',
      path: '',
      icon: '',
    },
    Create: {
      name: 'create',
      path: 'create',
      icon: '',
    },
    Created: {
      name: 'created',
      path: 'created',
      icon: '',
    },
    Join: {
      name: 'join',
      path: 'join/:roomName',
      icon: '',
    },
    Joined: {
      name: 'joined',
      path: 'joined',
      icon: '',
    },
    Scan: {
      name: 'Scan',
      path: 'scan',
      icon: '',
    },
  },
}

/* Route relations */
export default {
  Info,
  routes: [{
    path: `/`,
    component: Index,
    children: [
      {
        ...Info.routes.Default,
        component: Start,
      },
      {
        ...Info.routes.Create,
        component: Create,
      },
      {
        ...Info.routes.Created,
        component: Created,
      },
      {
        ...Info.routes.Join,
        component: Join,
      },
      {
        ...Info.routes.Joined,
        component: Joined,
      },
      {
        ...Info.routes.Scan,
        component: Scan,
      },
    ],
  }],
}
