import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import moduleInfo from './modules/mixins/module-info'
Vue.mixin(moduleInfo)

import routeAnimatorClient from './modules/lib/route-animations/route-animator-client'
Vue.mixin(routeAnimatorClient)

import networkInterface from './modules/lib/network-interface/network-interface-mixin'
Vue.mixin(networkInterface)

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js').then(registration => {
//       console.log('SW registered: ', registration);
//     }).catch(registrationError => {
//       console.log('SW registration failed: ', registrationError);
//     });
//   });
// }

new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App),
})