const storage = require('./storage')
const hat = require('hat')

let session = null

let key = 'session'
let customIdentity = null

if(window.frameElement) {
  key = window.frameElement.getAttribute('id')
  customIdentity = key
}

const MODE_CONTROLLER = 'controller'
const MODE_SCREEN = 'screen'

try {
  session = JSON.parse(storage.getKey(key))
}
catch(e) {
  console.info('No valid previous session found in cache.')
}

module.exports.setup = () => {
  if (session) {
    console.info('Found previous session', session)
  }
  else {
    session = {
      mode: null,
      identity: customIdentity || hat(),
      chosenName: '',
      roomName: null,
    }
    console.info('New session created', session)
  }
  
  session.setModeController = function () {
    this.mode = MODE_CONTROLLER
    this.store()
  }
  session.setModeScreen = function () {
    this.mode = MODE_SCREEN
    this.store()
  }
  
  session.setRoomName = function (roomName) {
    this.roomName = roomName
    this.store()
  }
  
  session.setChosenName = function (chosenName) {
    this.chosenName = chosenName
    this.store()
  }

  session.isController = function () {
    return this.mode === MODE_CONTROLLER
  }
  session.isScreen = function () {
    return this.mode === MODE_SCREEN
  }

  session.store = function () {
    try {
      storage.setKey(key, JSON.stringify(session))
    }
    catch(e) {
      console.error(e)
      console.warn('Failed to store session in storage', session)
      storage.setKey(key, undefined)
    }
  }
  
  session.store()
  
  return session
}