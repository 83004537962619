<template>
    <div>
        <Controller ref='controller' :keys="controllerConfig" :network="network" :session="session"></Controller>
        <ScreenMenu>
            <button class="stop-button" v-on:click="toggleMenu">Menu</button>
        </ScreenMenu>
        <ModalMenu v-if="modalMenuShowing">
            <h2>Paused</h2>
            <FullscreenButton></FullscreenButton>
            <button class="" v-on:click="stopGame">Stop game</button>
            <button class="" v-on:click="toggleMenu">Close menu</button>
        </ModalMenu>
    </div>   
</template>

<script>
import Controller from './../components/Controller'
import ScreenMenu from './../components/ScreenMenu'
import ModalMenu from './../components/ModalMenu'
import FullscreenButton from '../../lib/fullscreen/FullscreenButton'

import { mapGetters } from 'vuex'

export default {
    components: {
        Controller,
        ScreenMenu,
        ModalMenu,
        FullscreenButton,
    },
    computed: {
        ...mapGetters('NetworkInterface', [
            'network',
            'playing',
            'session',
        ]),
    },
    data() {
        return {
            controllerConfig: {
                upKey: 87, // w
                downKey: 83, // s
                upLabel: '',
                downLabel: ''
            },
            modalMenuShowing: false,
        }
    },
    methods: {
        toggleMenu() {
            this.modalMenuShowing = !this.modalMenuShowing
        },
        stopGame() {
            this.$store.dispatch('NetworkInterface/requestStopGame')
        },
    }
}
</script>