import Vue from 'vue'
import Vuex from 'vuex'

// import { register as registerLobby } from './modules/lobby/models'

import NetworkInterface from './modules/lib/network-interface/network-interface-store'
import RouteAnimation from './modules/lib/route-animations/route-animations'

// Init Vuex
Vue.use(Vuex)

// Create new instance of Database
// const database = new VuexORM.Database()

// Register Models and Modules
// registerLobby(database)

// Init Axios plugin
// VuexORM.use(VuexORMAxios, {
//   database,
// })

// Create the store
const store = new Vuex.Store({
  // plugins: [VuexORM.install(database)],
  modules: {
    NetworkInterface,
    RouteAnimation,
  },
  state: {
  },
  mutations: {
  },
})

export default store
