<template>
    <div ref='game-screen' class='game-screen' :class='{ ready: isReady }'>
        <ScreenMenu>
            <button class="stop-button" v-on:click="stopGame">STOP</button>
        </ScreenMenu>
        
        <div class='game'>
            <Controller v-if='!cpu1' ref='left-controller' :keys='this.leftControllerConfig' :updateDirection='this.updateDirectionLeftController'></Controller>
            <Screen ref='screen' :cpu1="cpu1" :cpu2="cpu2" :onGameReady="onGameReady"></Screen>
            <Controller v-if='!cpu2' ref='right-controller' :keys='this.rightControllerConfig' :updateDirection='this.updateDirectionRightController'></Controller>
        </div>
    </div>
</template>

<script>
    import Screen from './../components/Screen'
    import Controller from './../components/Controller'
    import ScreenMenu from '../components/ScreenMenu.vue'

    export default {
        components: {
            Screen,
            Controller,
            ScreenMenu,
        },

        props: {
            cpu1: {
                type: Boolean,
                default () { return false },
            },
            cpu2: {
                type: Boolean,
                default () { return false },
            },
        },

        data() {
            return {
                leftControllerConfig: {
                    upKey: 87, // w
                    downKey: 83, // s
                    upLabel: 'W',
                    downLabel: 'S'
                },

                rightControllerConfig: {
                    upKey: 73, // i
                    downKey: 75, // k
                    upLabel: 'I',
                    downLabel: 'K'
                },

                isReady: false,
            }
        },

        methods: {
            onGameReady () {
                this.isReady = true
            },
            updateDirectionLeftController(direction) {
                this.$refs['screen'].manipulatePaddle(0, direction)
            },

            updateDirectionRightController(direction) {
                this.$refs['screen'].manipulatePaddle(1, direction)
            },

            stopGame () {
                this.$router.push({ name: this.Lobby.routes.Default.name, params: { } })
            },

            fixDisplay() {
                // if the screen is taller than wide
                // adjust the main element to have width = height etc
                // make it absolute
                // rotate it
                
                if (window.innerHeight > window.innerWidth) {
                    this.$refs['game-screen'].setAttribute('style',`position: absolute; width: ${window.innerHeight}px; height: ${window.innerWidth}px; top: 0; left: 0; transform: rotate(90deg) translate(0, -${window.innerWidth}px); transform-origin: top left;`)    
                }
                else {
                    this.$refs['game-screen'].setAttribute('style','')
                }

                setTimeout(() => {
                    this.$refs['screen'].checkDimensions()
                })
            }
        },
        beforeRouteLeave(to, from, next) {
            // console.debug(to)
            // console.debug(from)
            this.isReady = false
            setTimeout(() => {
                this.$refs.screen.terminate()
                next()
            }, 1)
        },

        mounted() {
            window.addEventListener('resize', this.fixDisplay)
            screen.orientation.onchange = this.fixDisplay
            window.addEventListener('orientationchange', this.fixDisplay)
            this.fixDisplay()
        }

    }
</script>

<style lang="scss">
.game-screen {
  @import './../../../colors.scss';

  color: $main;
  height: 100vh;


  opacity: 0;
  &.ready {
    opacity: 1;
  }

  .game {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
  }

  .controller {
    position: relative;
    min-width: 100px;
    height: 220px;
    font-size: 1.2em;
    border: 2px solid $main;
    align-self: center;
  }

  .screen {
    // flex: 1;
  }

  .fullscreen-dialog {
    padding: 2em;
  }

//   background: linear-gradient(270deg, #24443c, #030a08);
//   background-size: 400% 400%;

//   -webkit-animation: AnimationName 30s ease infinite;
//   -moz-animation: AnimationName 30s ease infinite;
//   animation: AnimationName 30s ease infinite;

//   @-webkit-keyframes AnimationName {
//       0%{background-position:0% 50%}
//       50%{background-position:100% 50%}
//       100%{background-position:0% 50%}
//   }
//   @-moz-keyframes AnimationName {
//       0%{background-position:0% 50%}
//       50%{background-position:100% 50%}
//       100%{background-position:0% 50%}
//   }
//   @keyframes AnimationName {
//       0%{background-position:0% 50%}
//       50%{background-position:100% 50%}
//       100%{background-position:0% 50%}
//   }
}

</style>