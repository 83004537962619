<template>
    <div class="container">
        <h1 class="title glowText">Create Room</h1>
        <RoomQR></RoomQR>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import RoomQR from '../components/RoomQR'

    export default {
        components: {
            RoomQR,
        },
        data() {
            return {
            }
        },
        async mounted() {
            await this.$store.dispatch('NetworkInterface/createServer')
            this.$router.replace({ name: this.Lobby.routes.Created.name, params: { } })
        },
    }
</script>
