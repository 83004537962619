import { ANIMATIONS, DEFAULT_ANIMATIONS } from './route-animations'

export default {
  data () {
    return {
      ANIMATIONS,
      DEFAULT_ANIMATIONS,
    }
  },
  methods: {
    $_routeAnimation (animatorName, animation) {
      this.$store.dispatch('RouteAnimation/prepareAnimation', { animator: animatorName, animation })
    },
  },
}
