module.exports = function (bugout, session, cbs) {

  // store the latest message index for every client
  var messageState = {}

  bugout.on('message', function (address, msg) {
    console.log('received message from', address, 'message', msg)
    var clientId = msg.cid
    var messageNumber = msg.mnr
    var client = messageState[clientId]
    
    var processMessage = false

    if (client) {
      if (msg.type === 'player-ready') {
        client.messageNumber = messageNumber - 1
      }
      if (client.messageNumber < messageNumber) {
        processMessage = true
        client.messageNumber = messageNumber
      }
    }
    // first time message
    else {
      processMessage = true
      messageState[clientId] = {
        id: clientId,
        messageNumber: messageNumber
      }
    }

    if (processMessage) {
      if (cbs[msg.type]) {
        cbs[msg.type](msg)
      } else {
        console.log('Unknown event with type: ', msg.type)
      }
    }
  })

  var messageNumber = 0

  return function (tag, data) {
    messageNumber++
    bugout.send({
      cid: session.identity,
      mnr: messageNumber,
      type: tag,
      msg: data
    })
  }

}
