<template>
    <div class="container">
        <a data-cy="qr-code" class="link" :href="this.getJoinUrl()" target="_blank"><div><canvas id="qr-code"></canvas></div></a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

const qrCodeLib = require('../../node-qrcode/qrcode-draw')

export default {
    computed: {
        ...mapGetters('NetworkInterface', [
            'address',
        ])
    },
    watch: {
        address (address) {
            this.drawCode(address)
        }
    },
    mounted () {
        this.drawCode(this.address)
    },
    methods: {
        drawCode(roomName) {
            console.log('drawCode', roomName)
            const qrCodeDraw = new qrCodeLib.QRCodeDraw()
            qrCodeDraw.marginScaleFactor = 4
            qrCodeDraw.color = {
                dark: 'black',
                light: '#66FF00'
            }
            const joinUrl = this.getJoinUrl(roomName)
            console.log('joinUrl', joinUrl)
            createSmallestPossibleQR(3)

            function createSmallestPossibleQR(attemptedVersion) {
                qrCodeDraw.draw(document.getElementById('qr-code'), joinUrl, {
                    scale: 5,
                    errorCorrectLevel: 'L',
                    version: attemptedVersion
                }, function (error) {
                    if (error) {
                        console.log('QR failed, trying higer version: ', error)
                        createSmallestPossibleQR(++attemptedVersion)
                    }
                })
            }
        },
        getLocation() {
            return window.location.origin
        },

        getJoinUrl() {
            return this.getLocation() + '/join/' + ( this.address )
        },
    }
}
</script>

<style lang="scss" scoped>

    a {
        border: none;
    }
    canvas {
        align-self: center;
    }

</style>