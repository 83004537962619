const DISPATCH = 'MenuController/setOptions'
const DISPATCH_CLEAR = 'MenuController/clear'

export default {
  methods: {
    $_menuOptions (options) {
      this.$store.dispatch(DISPATCH, options)
    },
    $_menuOptionsClear () {
      this.$store.dispatch(DISPATCH_CLEAR)
    },
  },
}
