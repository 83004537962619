<template>
    <button class='button' v-if="fullscreenAvailable()" v-on:click='toggleFullscreen'>
        <span v-if="isFullscreen">Exit fullscreen</span>
        <span v-else>Go fullscreen</span>
    </button>
</template>

<script>

export default {
    data() {
        return {
            isFullscreen: !!document.fullscreenElement,
        }
    },

    mounted() {
        document.documentElement.onfullscreenchange = () => {
            this.isFullscreen = !!document.fullscreenElement
        }
    },

    methods: {
        toggleFullscreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen()
            } else if (document.exitFullscreen) {
                document.exitFullscreen()
            }
        },
        fullscreenAvailable() {
            return document.fullscreenEnabled
        },
    }
}
</script>