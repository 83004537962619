<template>
  <div id="app">
    <transition
        :name="routeAnimationName"
        :mode="routeAnimationMode"
        v-on:after-enter="$store.commit('RouteAnimation/resetAnimations')"
    >    
      <router-view class="router"></router-view>
    </transition>
  </div>
</template>

<script>
import RouteAnimator from './modules/lib/route-animations/route-animator'
import './App.scss'

import { mapGetters } from 'vuex'

export default {
  mixins: [RouteAnimator],
  name: 'App',
  components: {
  },

  data() {
    return {
      routeAnimatorName: 'drilldown',
    }
  },

  computed: {
      ...mapGetters('NetworkInterface', [
          'address',
          'players',
          'session',
          'room'
      ])
  },
  watch: {
      players: {
          handler (newVal, oldVal) {  
              console.log('players changed', newVal, oldVal)
          },
          deep: true,
      },
      room: {
        handler (newVal, oldVal) {
          console.log('App.vue watcher room changed', newVal, oldVal)

          if (newVal.playing && this.session.isController()) {
            this.$router.push({ name: this.Game.routes.Controller.name, params: { } })
            // this.$_routeAnimation('drilldown', this.ANIMATIONS.slideRight)
          }
          if (!newVal.playing && this.session.isController()) {
            this.$router.push({ name: this.Lobby.routes.Joined.name, params: { } })
            // this.$_routeAnimation('drilldown', this.ANIMATIONS.slideRight)
          }
        },
        deep: true,
      }
  },
  mounted () {
    // check if there is a previous session
    console.log('mounted', this.session)
    try {
      screen.orientation.lock('natural')
    }
    catch (e) {
      // meh
      console.log('catch', e)
    }
  },
  methods: {
      
  }
}
</script>
