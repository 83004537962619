<template>
    <div>
        <transition
            name="fade"
            mode="out-in"
        >
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters('NetworkInterface', [
                'connection',
                'session',
            ]),
        },

        mounted() {
            console.log('mounted index', this.connection, this.session)
            if (!this.connection && this.session.roomName && this.session.mode == 'controller') {
                this.$router.replace({ name: this.Lobby.routes.Join.name, params: { roomName: this.session.roomName } })
            }
            if (!this.connection && this.session.mode == 'screen') {
                this.$router.replace({ name: this.Lobby.routes.Create.name })
            }
        }
    }
</script>
