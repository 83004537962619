class Paddle {

  game = null
  paddleId = null

  sound = null
  sprite = null
  tween = null
  direction = 'neutral'

  constructor(game, paddleId, sound) {
    this.game = game
    this.paddleId = paddleId
    this.sound = sound

    // Determine position for this paddle
    let worldX
    let anchorX
    switch (paddleId) {
      case( 'left'):
        worldX = 0
        anchorX = 0
        break
      case( 'right'):
        worldX = game.world.width
        anchorX = 1
        break
      default:
        break
    }

    // Build this paddle's sprite
    this.sprite = game.add.sprite(worldX, game.world.centerY, 'paddle')
    this.sprite.anchor.setTo(anchorX, 0.5)

    // Physics
    game.physics.enable(this.sprite, window.Phaser.Physics.ARCADE)
    this.sprite.body.collideWorldBounds = true
    this.sprite.body.bounce.set(1)
    this.sprite.body.immovable = true
    this.sprite.body.setSize(25, 90, 0, 0)
    this.sprite.tint = 0x00ff00

    // Add parent property in the sprite, to recognize it in Phaser callbacks
    if(Object.prototype.hasOwnProperty.call(this.sprite,'owner')) {
    // if(this.sprite.hasOwnProperty('owner')) {
      console.error('Property already defined')
    }
    this.sprite.owner = this
  }

  tweenOn() {
    let colorBlend = {step: 0}
    this.tween = this.game.add.tween(colorBlend).to({step: 25}, 100, window.Phaser.Easing.Default, false)
    this.tween.onUpdateCallback(() => {
      this.sprite.tint = window.Phaser.Color.interpolateRGB(0,255,0, 255,255,255, 25, colorBlend.step);
    })
    this.tween.start()
    this.tween.onComplete.addOnce(this.tweenOff, this)
  }

  tweenOff() {
    let colorBlend = {step: 0}
    this.tween = this.game.add.tween(colorBlend).to({step: 100}, 300, window.Phaser.Easing.Default, false)
    this.tween.onUpdateCallback(() => {
      this.sprite.tint = window.Phaser.Color.interpolateRGB(255,255,255, 0,255,0, 100, colorBlend.step);
    })
    this.tween.start()
    this.tween.onComplete.removeAll()
  }

  update() {
    if (this.direction === 'down') {
      this.sprite.y = this.sprite.y + 5
    }
    if (this.direction === 'up') {
      this.sprite.y = this.sprite.y - 5
    }
  }

  render() {

  }

  collideBall() {
    switch(this.paddleId) {
      case('left'):
        // this.sendFn('paddleHit', this.room.players[0])
        this.sound.play('paddle left');
        break
      case('right'):
        // this.sendFn('paddleHit', this.room.players[1])
        this.sound.play('paddle right');
        break
      default:
        break
    }
  }

  perfectHit() {
    this.tweenOn()
  }

}

export default Paddle
