export const ANIMATIONS = {
    slideLeft: {
        name: 'slide-left',
        mode: '',
    },
    slideRight: {
        name: 'slide-right',
        mode: '',
    },
    fade: {
        name: 'fade',
        mode: '',
    },
}

export const DEFAULT_ANIMATIONS = {
    modules: ANIMATIONS.fade,
    submodules: ANIMATIONS.fade,
    drilldown: ANIMATIONS.fade,
}

export default {
    namespaced: true,
    state: {
        animations: {
            ...DEFAULT_ANIMATIONS,
        },
    },
    mutations: {
        setAnimation (state, payload) {
            state.animations[payload.animator] = payload.animation
        },
        resetAnimations (state) {
            state.animations = { ...DEFAULT_ANIMATIONS }
        },
    },
    actions: {
        prepareAnimation ({ commit }, payload) {
            commit('setAnimation', payload)
        },
    },
}
