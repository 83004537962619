<template>
    <div ref="element" class="screen-menu">
        <slot></slot>
    </div>
</template>

<script>

export default {

    props: {
    },

    data() {
        return {
        }
    },

    mounted() {
    },

    methods: {
    }

}
</script>

<style lang="scss">
    @import './../../../colors.scss';

    .screen-menu {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 8888;
        pointer-events: none;
    }
</style>