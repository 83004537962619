<template>
      <div ref="element" class="controller">

        <div class="mobile-controls">
          <div ref="touchpad" class="touchpad">
            <div ref="button-up" class="controls-button up">
              <span class="up-button">&#10096;&#10096;</span>
                {{ this.keys.upLabel }}
            </div>
            <div ref="button-down" class="controls-button down">
                {{ this.keys.downLabel }}
              <span class="down-button">&#10097;&#10097;</span>
            </div>
          </div>
        </div>

      </div>
</template>

<script>
const directionRules = {
    up: 'up',
    down: 'down',
    neutral: 'neutral'
}

export default {

    props: {
        keys: Object,
        updateDirection: Function,
        network: Object,
        session: Object,
    },

    data() {
        return {
            element: null,
            buttonUp: null,
            buttonDown: null,
            touchpad: null,

            defaultDir: directionRules.neutral,
            direction: this.defaultDir,

            pressedButtons: []
        }
    },

    mounted() {
        this.element = this.$refs['element']
        this.buttonUp = this.$refs['button-up']
        this.buttonDown = this.$refs['button-down']
        this.touchpad = this.$refs['touchpad']
        this.setupKeyHandlers()
        this.setupTouchHandlers()
        this.setupCallbacks()
    },

    methods: {
        setupKeyHandlers() {
            document.addEventListener('keydown', (e) => {
                let key = e.keyCode
                let d
                switch (key) {
                    case this.keys.upKey:
                        if (this.pressedButtons.indexOf('up') < 0) {
                            this.pressedButtons.push('up')
                            d = "up"
                        }
                        e.preventDefault()
                        break
                    case this.keys.downKey:
                        if (this.pressedButtons.indexOf('down') < 0) {
                            this.pressedButtons.push('down')
                            d = "down"
                        }
                        e.preventDefault()
                        break
                    default:
                        break
                }
                if (d) {
                    this.setDirection(d)
                }
            })

            document.addEventListener('keyup', (e) => {
                let key = e.keyCode
                let d
                switch (key) {
                    case this.keys.upKey:
                        this.pressedButtons.splice(this.pressedButtons.indexOf('up'), 1)
                        e.preventDefault()
                        break
                    case this.keys.downKey:
                        this.pressedButtons.splice(this.pressedButtons.indexOf('down'), 1)
                        e.preventDefault()
                        break
                    default:
                        break
                }
                d = this.pressedButtons.length ? this.pressedButtons[this.pressedButtons.length - 1] : directionRules.neutral
                this.setDirection(d)
            })
        },

        onUpdateDirection(direction) {
            if (this.updateDirection) {
                this.updateDirection(direction)
            }
            if (this.network) {
                this.network.send('paddleMove', direction)
            }
        },

        setDirection(newDirection) {
            if (this.direction !== directionRules[newDirection]) {
                this.direction = newDirection
                this.buttonUp.classList.remove('active-up-down-button')
                this.buttonDown.classList.remove('active-up-down-button')
                switch (newDirection) {
                    case (directionRules.up):
                        this.buttonUp.classList.add('active-up-down-button')
                        break
                    case (directionRules.down):
                        this.buttonDown.classList.add('active-up-down-button')
                        break
                    case (directionRules.neutral):
                        break
                    default:
                        break
                }
                this.onUpdateDirection(newDirection)
            }
        },

        setupTouchHandlers() {

            // Register listeners
            this.touchpad.addEventListener('touchmove', handleMove.bind(this), true)
            this.touchpad.addEventListener('touchend', handleEnd.bind(this), true)
            this.touchpad.addEventListener('touchstart', handleStart.bind(this), true)
            this.touchpad.addEventListener('touchcancel', handleCancel.bind(this), true)
            
            function isInRect(x,y,rect) {
                return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
            }

            function handleTouchpad(e) {
                const buttonUp = this.$refs['button-up'].getBoundingClientRect()
                const buttonDown = this.$refs['button-down'].getBoundingClientRect()

                if (isInRect(e.clientX, e.clientY, buttonUp)) {
                    this.setDirection(directionRules.up)
                }
                else if (isInRect(e.clientX, e.clientY, buttonDown)) {
                    this.setDirection(directionRules.down)
                }
            }

            let ongoingTouches = []

            function copyTouch(touch) {
                return {
                    identifier: touch.identifier,
                    clientX: touch.clientX,
                    clientY: touch.clientY
                };
            }

            function ongoingTouchIndexById(idToFind) {
                for (var i = 0; i < ongoingTouches.length; i++) {
                    var id = ongoingTouches[i].identifier;

                    if (id === idToFind) {
                        return i;
                    }
                }
                return -1; // not found
            }

            function updateDirection() {
                if (ongoingTouches.length) {
                    handleTouchpad.call(this, ongoingTouches[ongoingTouches.length - 1])
                } else {
                    this.setDirection(directionRules.neutral)
                }
            }

            function handleStart(evt) {
                // console.log('handleStart',evt)
                evt.preventDefault()
                var touches = evt.changedTouches;

                for (var i = 0; i < touches.length; i++) {
                    ongoingTouches.push(copyTouch(touches[i]));
                }

                updateDirection.apply(this)
            }

            function handleMove(evt) {
                // console.log('handleMove',evt)
                evt.preventDefault()
                var touches = evt.changedTouches;

                for (var i = 0; i < touches.length; i++) {
                    var idx = ongoingTouchIndexById(touches[i].identifier)

                    if (idx >= 0) {
                        ongoingTouches.splice(idx, 1, copyTouch(touches[i])) // swap in the new touch record
                    } else {
                        console.warn('can\'t figure out which touch to continue')
                    }
                }

                updateDirection.apply(this)
            }

            function handleEnd(evt) {
                // console.log('handleEnd',evt)
                evt.preventDefault()
                var touches = evt.changedTouches;

                for (var i = 0; i < touches.length; i++) {
                    var idx = ongoingTouchIndexById(touches[i].identifier);
                    if (idx >= 0) {
                        ongoingTouches.splice(idx, 1); // remove it; we're done
                    } else {
                        console.error("can't figure out which touch to end");
                    }
                }

                updateDirection.apply(this)
            }

            function handleCancel(evt) {
                // console.log('handleCancel',evt)
                evt.preventDefault()
                var touches = evt.changedTouches;

                for (var i = 0; i < touches.length; i++) {
                    ongoingTouches.splice(i, 1); // remove it; we're done
                }

                updateDirection.apply(this)
            }
        },

        setupCallbacks() {
            if (!this.network) {
                return
            }
            this.network.callbacks['paddleHit'] = (message) => {
                if (message.msg === this.session.identity) {
                    navigator.vibrate(20)
                }
            }
        }
    }

}
</script>

<style lang="scss">
    @import './../../../colors.scss';

    .controller {
        background-color: black;
        margin: 1em;

        .touchpad {
            width: 100%;
            height: 100%;
            left: 0;
            display: table;
        }

        .controls-button {
            font-size: 1em;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            height: 50%;
            -webkit-user-select: none;
            /* Chrome all / Safari all */
            -moz-user-select: none;
            /* Firefox all */
            user-select: none;
            pointer-events: none;
            box-sizing: border-box;
        }

        .up-button {
            text-align: center;
            transform: rotate(90deg);
        }

        .down-button {
            text-align: center;
            transform: rotate(90deg);
        }

        .active-up-down-button {
            background-color: $main;
            color: black;
        }

        .mobile-controls {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>