import { mapState } from 'vuex'

export default {
  data () {
      return {
          routeAnimatorName: 'default',
      }
  },
  computed: {
    ...mapState('RouteAnimation', {
        routeAnimationName: function (state) {
            return state.animations[this.routeAnimatorName].name
        },
        routeAnimationMode: function (state) {
          return state.animations[this.routeAnimatorName].mode
        },
    }),
  },  
}
